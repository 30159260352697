@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f1f1f1;
}

:root {
  --primary: #319bdb;
  --primary-hover: #4893ae;
  --secondary: #f35d74;
  --secondary-hover: #dd5469;
  --primary-300: #226a97;
  --accent-100: #ffadad;
  --accent-200: #ffd6a5;
  --text-100: #4b4f5d;
  --text-200: #6a738b;
  --bg-100: #ffffff;
  --bg-200: #f5f5f5;
  --bg-300: #cccccc;
  --bg-black: #334665;
}

/* Barra de rolagem para navegadores baseados em WebKit */
::-webkit-scrollbar {
  width: 4px; /* Largura da barra de rolagem */
  height: 4px; /* Altura da barra de rolagem horizontal */
}

/* Fundo da barra de rolagem */
::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 4px;
}

/* Barra de rolagem propriamente dita */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 4px;
}

/* Quando o usuário passa o mouse sobre a barra de rolagem */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: inherit !important;
  transition: background-color 5000s ease-in-out 0s !important;
}

.desktop-view {
  width: 1280px !important;
  max-width: none !important;
  margin: 0 auto;
}

.bg-primary-300 {
  background: var(--primary-300);
}

body {
  background-color: var(--primary);
  font-family: "Montserrat", Helvetica, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  height: calc(100vh - 65px);
}

aside {
  border-radius: 0 0 10px 0;
  overflow: hidden;
  border: none;
}

aside > div {
  background-color: var(--primary) !important;
}

aside ul li a span {
  color: var(--bg-100);
}

aside ul li > a.active {
  background-color: var(--bg-100);
}

aside ul li > a.active span {
  color: var(--bg-black);
}

aside .active-submenu {
  background: var(--bg-black);
}

.mainbar {
  background: var(--primary);
  width: 100%;
  height: 65px;
  padding-top: 15px;
  color: var(--bg-100);
}

.app-container {
  border-radius: 10px 0 0 0;
  background: var(--bg-200);
  overflow: scroll;
  height: calc(100vh - 65px);
}

.white-container {
  position: fixed;
  height: 60vh;
  background: var(--bg-200);
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

[aria-disabled="true"] {
  background: white !important;
}

.focus\:ring-cyan-300:focus {
  --tw-ring-color: transparent !important;
}

.title-syncx {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  color: rgb(79, 79, 79);
}

.title-syncx.white {
  color: white;
}

.title-syncx::before {
  content: "";
  height: 20px;
  width: 5px;
  border-radius: 10px;
  background: var(--primary);
}
.title-syncx.white::before {
  content: "";
  height: 20px;
  width: 5px;
  border-radius: 10px;
  background: white;
}

.title-vehicle {
  display: flex;
  padding: 5px 10px;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  color: white;
  background-color: #63687a;
}

.card-query {
  cursor: pointer;
  transition: color 0.3s, font-weight 0.3s;
  background: white;
}

.card-query > div {
  padding: 10px;
  min-height: 50px;
  font-size: 0.8rem;
  font-weight: 500;
}

.card-query > div > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-query.active {
  background: var(--primary);
  color: #fff;
  font-weight: 500;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.styled-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.styled-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--primary-300);
  border-radius: 10px;
}

@media screen and (max-width: 639px) {
  .app-container {
    border-radius: 10px 10px 0 0;
    overflow: auto;
    height: auto;
    padding-bottom: 80px;
  }
}

button.primary,
.bg-primary,
.bg-cyan-700 {
  background: var(--primary);
}

button.secondary,
.bg-secondary {
  background: var(--bg-black);
}

button.primary:hover {
  background: var(--primary-hover) !important;
}

button.secondary:hover {
  background: var(--secondary-hover) !important;
}
.color-primary,
.text-cyan-600 {
  color: var(--primary);
}

.color-secondary {
  color: var(--secondary);
}

.border-cyan-600 {
  border-color: var(--primary);
}

input:focus,
select:focus {
  border-color: var(--primary) !important;
}

.card-dashboard > div {
  justify-content: flex-start;
}

.card-next-appointments > div {
  padding: 10px !important;
}

.card-invoice > div,
.card-working-hours > div {
  padding: 16px !important;
}

@media screen and (max-width: 768px) {
  .video-tutorials {
    width: 100%;
    height: 200px;
  }
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 20px;
  border-bottom: solid 3px var(--primary);
  width: fit-content;
  padding-bottom: 5px;
}

.loader-syncx {
  width: 48px;
  height: 48px;
  border: 3px dotted rgb(98, 98, 98);
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader-syncx::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted var(--primary);
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.container-alerts {
  display: grid;
  grid-gap: 5px;
  /* Para dispositivos desktop */
  grid-template-columns: repeat(3, 1fr);
}

.container-alerts.two-columns {
  grid-template-columns: repeat(2, 1fr);
}

.container-alerts.four-columns {
  grid-template-columns: repeat(4, 1fr);
}

.container-alerts.five-columns {
  grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 780px) {
  .card-pdf {
    min-width: 1050px;
  }
}

.loader-car {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.car__body {
  -webkit-animation: shake 0.2s ease-in-out infinite alternate;
  animation: shake 0.2s ease-in-out infinite alternate;
}
.car__line {
  transform-origin: center right;
  stroke-dasharray: 22;
  -webkit-animation: line 0.8s ease-in-out infinite;
  animation: line 0.8s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.car__line--top {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.car__line--middle {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.car__line--bottom {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}
@-webkit-keyframes line {
  0% {
    stroke-dashoffset: 22;
  }
  25% {
    stroke-dashoffset: 22;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -22;
  }
  100% {
    stroke-dashoffset: -22;
  }
}
@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }
  25% {
    stroke-dashoffset: 22;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -22;
  }
  100% {
    stroke-dashoffset: -22;
  }
}

.square {
  width: 100%;
  height: 30px;
  background: linear-gradient(to right, red, yellow, green);
  border-radius: 15px;
  margin: 20px 0;
  position: relative;
}

.square > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.word {
  animation: slideUp 3s infinite;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  25% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  margin-left: 16px;

  border-top: 14px solid var(--primary);
}

@media print {
  body,
  html,
  .print-content {
    background: white !important;
  }
  .print-content {
    zoom: 0.6; /* ajusta para caber */
  }

  .print-padding {
    padding: 20px !important;
  }

  @page {
    size: A4;
    margin: 0;
  }
}
